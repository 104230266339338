@import './default.css';
.banner-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: rgba(4, 4, 89, 0.947);
  background-image: linear-gradient(
    180deg,
    rgb(134 204 251 / 46%),
    rgb(40 117 168 / 66%)
    ),
    url('../images/photo-delivery.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-text-wrapper {
  position: absolute;
  left: 13%;
  margin-top: 20%;
  color: #fffdfd;
  max-width: 25%;
  text-align: left;
  font-size: 1rem;
}
.banner-text-wrapper h2 {
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 8px;
  color: white;
}
.banner-text-wrapper h2 p {
  color: #0383c7;
  display: inline-block;
  margin: 0;
  font-weight: bold;
}
.banner-text-wrapper .line {
  width: 0.8px;
  height: 76px;
  position: absolute;
  background: rgba(255, 255, 255, 0.44);
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.03)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.03)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.03)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.03)
  );
  top: 16px;
  right: -25px;
}
.banner-text-wrapper > p {
  margin: 10px auto 24px;
  font-size: 16px;
}
.banner-text-wrapper .start-button {
  margin-bottom: 24px;
  text-align: right;
}
.banner-text-wrapper .start-button > a {
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid tint(20%);
  text-decoration: none;
  color: #0ae;
  font-size: 15px;
  background: transparent;
  transition: all 0.45s;
  text-align: center;
  line-height: 36px;
  margin-left: 8px;
}
.banner-text-wrapper .start-button > a:hover {
  color: #fff;
  background: tint(20%);
}
.banner-text-wrapper .github-btn {
  float: right;
  line-height: 14px;
  text-align: left;
}
.banner-text-wrapper .github-btn a:hover {
  color: #333;
}
.img-banner {
  position: relative;
  /* z-index: 3; */
  left: 56%;
  width: 40%;
  margin-top: 7%;
}
.banner-header {
  display: flex;
  justify-content: space-around;
}
.banner-header img {
  vertical-align: middle;
  border-style: none;
  position: absolute;
  width: 50%;
  right: 10px;
}
.down {
  text-align: center;
  position: absolute;
  bottom: 30px;
  color: rgba(255, 255, 255, 0.75);
  left: 50%;
  margin-left: -7px;
  animation: upDownMove 1.2s ease-in-out infinite alternate;
}
.page {
  min-height: 700px;
  height: 100vh;
}
.content-wrapper {
  width: 100%;
  overflow: hidden;
}
.content-wrapper .image-wrapper {
  width: 65%;
  float: left;
  position: relative;
  top: 30%;
}
.content-wrapper .text-wrapper {
  width: 35%;
  float: left;
  position: relative;
  top: 20%;
  left: -30px;
  font-family: Lato;
  /* z-index: 1; */
}
.content-wrapper .text-wrapper h2,
.content-wrapper .text-wrapper-bottom h2 {
  font-size: 32px;
  color: #314659;
  font-weight: normal;
  white-space: nowrap;
}
.content-wrapper .text-wrapper p {
  margin: 10px 0 20px;
  font-size: 16px;
  line-height: 28px;
  color: #797777;
}
.content-wrapper .left-text {
  padding-left: 10%;
}
.image2 {
  background: url('../images/map.png') no-repeat left / 100%;
  height: 800px;
  top: 30%;
  margin-top: -266px;
}
.image3 {
  padding-right: 10%;
  background: url('https://t.alipayobjects.com/images/T1nx0kXdFfXXXXXXXX.png')
    no-repeat right / 639px;
  background-origin: content-box;
  height: 500px;
  top: 50%;
  margin-top: -250px;
}
.image4 {
  background: url('https://t.alipayobjects.com/images/T1lyJkXg4aXXXXXXXX.png')
    no-repeat center / 615px;
  height: 364px;
  margin: auto;
}
.text-wrapper-bottom {
  text-align: center;
  margin: 10% auto 40px;
}
.text-wrapper-bottom p {
  margin: 20px auto;
  font-size: 16px;
  line-height: 28px;
  color: #999;
}
.c-services {
  position: relative;
  padding: 60px 5%;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
}
.c-services-card {
  display: flex;
  margin-bottom: 20%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
}
.title-services {
  margin: 20px;
  font-size: 60px;
}
.card-img {
  width: 80%;
}
.c-tracking {
  background-color: #ebebec73;
  padding: 100px;
  align-content: center;
}
#lista {
  font-size: 20px;
  width: 160%;
  margin-left: -1%;
}
#lista li {
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
}
#points-map {
  list-style-type: none;
  min-height: 1px;
}
#points-map li {
  font-size: 14px;
  padding-bottom: 0;
  color: rgb(99, 95, 95);
}
.c-fleet-bg {
  padding-top: 3%;
  max-height: 1000px;
}
#platform {
  background: linear-gradient(
      180deg,
      #ffffffde,
      #ffffffa5
    ),
    url('../images/route.svg');
  background-position-x: 800px;
  background-repeat: no-repeat;
  padding: 50px;
  height: auto;
  /* background-color: #0095d775; */
}
.c-platform {
  width: 60%;
}
.c-platform-list li {
  list-style: none;
  text-align: left;
  margin-top: 28px;
  font-size: 20px;
}

.text-plaform {
  text-align: center;
  margin: 2% auto 40px;
}
.banner-text {
  width: 800px;
}
.title-banner {
  font-size: 60px;
  width: 800px;
}
.content-banner {
  font-size: 18px;
  font-weight: bold;
  text-align: justify;
}
#hubs {
  height: 764px;
  background-color: #e6f5ff;
}
.title-hubs {
  width: 402px;
}
.img-plat1 {
  width: 300px;
}
.img-plat2 {
  width: 300px;
}
.img-plat3 {
  width: 300px;
  margin-right: 20px;
}
.politicas {
  max-height: 550px;
  overflow-x: auto;
  border: #8080801f solid;
  padding: 20px;
}
@keyframes upDownMove {
  to {
    transform: translateY(100px);
  }
}

.CookieDeclaration {
  padding: 45px;
}

.CookieDeclaration .show {
  visibility: hidden;
}

.cookies-consent {
  display: flex;
  justify-content: center;
  margin: 10px;
  min-height: 400px;
}