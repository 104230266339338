.nav-phone-icon {
  top: 32px;
  z-index: 1;
  width: 16px;
  height: 22px;
  cursor: pointer;
}
@media only screen and (min-width: 0) and (max-width: 1280px) {
  .en-us #search-box {
    display: none;
  }
  .c-platform {
    width: 100%;
  }
  .action-btn {
    position: absolute;
    right: 40px;
    margin-top: 530px;
  }
}
@media only screen and (min-width: 0) and (max-width: 1180px) {
  .zh-cn #search-box {
    display: none;
  }
  .action-btn {
    position: absolute;
    right: 40px;
    margin-top: 530px;
  }
}
@media only screen and (min-width: 0) and (max-width: 992px) {
  #search-box {
    display: none;
  }
  .code-boxes-col-2-1,
  .code-boxes-col-1-1 {
    float: none;
    width: 100%;
  }
  .preview-image-boxes {
    margin: 0 !important;
    float: none;
    width: 100%;
  }
  .preview-image-box {
    padding-left: 0;
    margin: 10px 0;
  }
  a#logo {
    width: 206px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    display: block;
  }
  .banner-entry {
    position: relative;
    top: 30px;
    left: 0;
    text-align: center;
  }
  .image-wrapper {
    display: none;
  }
  .banner-wrapper {
    background-position: 40%;
    background-size: auto;
  }
  .content-wrapper .text-wrapper {
    float: none;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 0;
  }
  .content-wrapper .text-wrapper > p {
    max-width: 100% !important;
    padding: 0 40px;
  }
  .content-wrapper.page {
    min-height: 300px;
    height: auto;
  }
  .banner-text-wrapper {
    left: 5%;
    transform: translateX(-50%);
    text-align: center;
  }
  .banner-text-wrapper h2 {
    font-size: 26px;
    text-align: left;
    width: 350px;
  }
  .banner-text-wrapper .start-button {
    text-align: center;
  }
  .banner-text-wrapper .start-button > a {
    margin: 0 4px;
  }
  .banner-text-wrapper .line {
    display: none;
  }
  div.version {
    display: block;
    margin: 29px auto 16px;
  }
  div.version > .ant-select-selection {
    color: #314659;
  }
  div.version > .ant-select-selection:not(:hover) {
    border-color: #314659;
  }
  .popover-menu {
    width: 300px;
  }
  .popover-menu button.lang {
    margin: 16px auto;
    float: none;
  }
  .popover-menu div.version {
    margin: 32px auto 16px;
    float: none;
  }
  .popover-menu .ant-popover-inner {
    overflow: hidden;
  }
  .popover-menu .ant-popover-inner-content {
    padding: 0;
  }
  ul#nav,
  ul#nav li {
    width: 100%;
    font-size: 14px;
    color: black;
  }
  ul#nav li {
    color: rgb(240, 240, 240);
    font-size: 50px;
  }
  ul#nav li .header-link {
    color: #333;
  }
  .toc {
    display: none;
  }
  .nav-phone-icon {
    font-size: 100px;
    display: block;
  }
  .nav-phone-icon:before {
    content: '';
    display: block;
    border-radius: 2px;
    width: 16px;
    height: 2px;
    background: rgb(124, 30, 30);
    box-shadow: 0 6px 0 0 #777, 0 12px 0 0 #777;
    position: absolute;
  }
  .main {
    height: calc(14%);
  }
  .aside-container {
    float: none;
    width: auto;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
    border-right: 0;
    margin-bottom: 30px;
  }
  .main-container {
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
  }
  .main-container > .markdown > * {
    width: 100% !important;
  }
  .main-wrapper {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
  footer {
    text-align: center;
  }
  footer ul li {
    float: none;
    width: auto;
  }
  footer ul li > h2 .anticon {
    display: none;
  }
  .card {
    font-size: 16px;
  }
  .img-banner {
    margin-top: 130%;
    margin-left: -50%;
    margin-bottom: 20px;
    width: 90%;
  }
  .banner-text {
    margin-top: 40%;
    margin-left: 50%;
    width: 300px;
  }
  .content-banner {
    font-size: 14px;
    font-weight: bold;
    text-align: justify;
  }
  .banner-header {
    margin-top: 15%;
  }
  .title-hubs {
    width: 100%;
    text-align: center;
  }
  #lista {
    text-align: left;
    padding-left: 20px;
  }
  #points-map {
    padding-left: 20px;
    text-align: left;
  }

  #points-map li p {
    font-size: 16px;
  }
  .img-plat1 {
    width: 300px;
  }
  .img-plat2 {
    width: 300px;
  }
  .img-plat3 {
    width: 300px;
  }
  #platform {
    background: linear-gradient(180deg, rgb(255, 255, 255), rgb(255, 255, 255)),
      url('../images/take-away-animate.svg');
    margin-top: 16%;
  }
  .c-platform-list li {
    font-size: 20px;
    width: 100%;
    list-style: circle;
    text-align: left;
    line-height: 30px;
  }
  .play-apps {
    padding-left: 0%;
  }
  .play-store {
    width: 50%;
  }
  .apple-store {
    width: 44%;
    height: 1%;
    padding-left: 12px;
  }

  .contenedor-chatbot {
    width: 256px;
    position: fixed;
    right: 120px;
    bottom: 10px;
    margin-top: 100px;
  }

  .action-btn {
    position: absolute;
    right: 40px;
    margin-top: 530px;
  }

  .ant-btn-round.ant-btn-lg {
    height: 40px;
    border-color: #52C419;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
    border-width: 2px;
  }
  
  .ant-btn-circle.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%;
    border-color: #52C419;
  }

  .frame-chat {
    position: initial;
  }
}

@media only screen and (min-width: 2500px) {
  .c-platform-list {
    margin-left: -150px;
  }
  #platform {
    padding: 42px 10% 45px 10%;
    background-position-x: 1200px;
  }
  .content-banner {
    font-size: 25px;
    font-weight: bold;
    text-align: justify;
  }
  .play-store {
    width: 24%;
  }
  .apple-store {
    width: 19%;
    height: 1%;
    padding-top: 1px;
  }
}
