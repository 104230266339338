* {
	margin: 0;
	padding: 0;
}
.accordian {
	max-width: 805px;
	max-height: 800px;
	overflow: hidden;
	margin: 100px auto;
}
