* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.container {
  padding: 48px;
}
.input-search {
  box-sizing: border-box;
  margin: 0;
  padding-top: 26px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  display: flex; /* 改为 flex 布局 */
  align-items: center; /* 垂直居中对齐 */
  width: 100%;
  position: absolute; /* 如果是为了定位，确保父级有 position: relative */
  z-index: 3;
}
.button-search {
  color: #fff;
  background: #1890ff;
  padding: 8px; /* 调整按钮内边距，保证点击区域 */
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 40px; /* 控制按钮宽度 */
  text-align: center;
  display: flex; /* 使用 flex 布局让图标居中 */
  align-items: center;
  justify-content: center;
}

.button-search-portal {
  color: #fff;
  background: #1890ff;
  margin-left: -4px;
  padding: 15px;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  position: relative;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 34px;
  text-align: center;
}

.input-search-portal {
  height: 48px;
  padding-top: 13px;
}

#list-cookies li {
  list-style: none;
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
}

.play-apps {
  padding-left: 46%;
}

.play-store {
  width: 18%;
}
.apple-store {
  width: 14%;
}

.select-languaje img {
  padding-right: 20px;
}
